import { Component, inject, Input, OnInit } from '@angular/core';
import { RetrieveReviewReasonsEvent } from '@pipelines/app/models/requests/intents/retrieve-review-reasons-event';
import { applicationType } from '@common/lib/constants/application-types';
import { RetrieveReviewReasonsResult } from '@pipelines/app/models/update-results/retrieve-review-reasons-result';
import { Observable } from 'rxjs';
import { PipelineEventManager } from '@pipelines/app/manager/pipeline-event.manager';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'pipelines-review-reasons',
	templateUrl: './review-reasons.component.html',
	styleUrls: ['./review-reasons.component.scss'],
})
export class ReviewReasonsComponent implements OnInit {
	private readonly pipelineEventManager = inject(PipelineEventManager);
	private readonly snackBar = inject(MatSnackBar);

	@Input() public reviewReasons: string[];
	@Input() public onMenuOpenedEvent: Observable<void>;
	@Input() public applicationType: string;
	@Input() public applicationId: string;
	public maxReviewReasonsToDisplay = 3;
	public isRefreshing: boolean = true;

	ngOnInit(): void {
		this.onMenuOpenedEvent.subscribe(() => {
			this.onGetReviewReasons(null);
		});
	}

	public onGetReviewReasons(evt?: MouseEvent) {
		evt?.stopPropagation();

		this.isRefreshing = true;

		const retrieveReviewReasonsEvent = new RetrieveReviewReasonsEvent();
		retrieveReviewReasonsEvent.templateName = `deposits-${this.applicationType === applicationType.Individual ? 'personal' : 'business'}`;

		this.pipelineEventManager.processApplicationEvent(retrieveReviewReasonsEvent, this.applicationId).subscribe({
			next: (response) => {
				this.reviewReasons = (response.result as RetrieveReviewReasonsResult)?.reviewReasons;
				this.isRefreshing = false;
			},
			error: () => {
				this.isRefreshing = false;
				this.snackBar.open('An error occurred while processing your request. Please try again.', null, {
					duration: 3000,
				});
			},
		});
	}
}
