import { UserFilterProfileResponse } from '@pipelines/app/models/responses/user-filter-profile.response';
import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { UserFilterProfile } from '@pipelines/app/models/user-filter-profile';
import { ApiPipelineViewToPipelineViewAdapter } from '@pipelines/app/adapters/api-pipeline-view-to-pipeline-view.adapter';

const apiPipelineViewToPipelineViewAdapter = new ApiPipelineViewToPipelineViewAdapter();

export class UserFilterProfileResponseToUserFilterProfileAdapter extends AdapterBase<
	UserFilterProfileResponse,
	UserFilterProfile
> {
	adapt(source: UserFilterProfileResponse): UserFilterProfile {
		const userFilterProfile = new UserFilterProfile();

		if (source) {
			userFilterProfile.id = source.id;
			userFilterProfile.defaultViews = apiPipelineViewToPipelineViewAdapter.adaptCollection(source.defaultViews);
			userFilterProfile.savedViews = apiPipelineViewToPipelineViewAdapter.adaptCollection(source.savedViews);
		}

		return userFilterProfile;
	}
}
