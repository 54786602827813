import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteType } from '@common/lib/models/enum/route-type.enum';
import { PresentationDefinition } from '@common/lib/models/presentation-definition';
import { from, Observable } from 'rxjs';
import { InternalStateService } from '@experience/app/services/internal-state.service';

@Injectable({
	providedIn: 'root',
})
export class LegacyRoutingService {
	private internalStateService = inject(InternalStateService);
	public currentRoute: string;
	public initialRoute: string;

	constructor(private router: Router) {}

	public navigateToUrl$(route: string): Observable<boolean> {
		return from(this.router.navigateByUrl(route));
	}

	public setInitialRoute(value: string) {
		this.initialRoute = value;
	}

	public setCurrentRoute(value: string) {
		this.currentRoute = value;
		this.internalStateService.setCurrentScreen(value);
	}

	public getRoute(routeType: RouteType, presentationDefinitions: PresentationDefinition[]): string {
		switch (routeType) {
			case RouteType.next:
				return presentationDefinitions.find((presDef) => presDef.route === this.currentRoute).next;
			case RouteType.previous:
				return presentationDefinitions.find((presDef) => presDef.route === this.currentRoute).previous;
			case RouteType.initial:
				return this.initialRoute;
			default:
				return null;
		}
	}
}
