import { Component, inject, OnInit } from '@angular/core';
import { UserFilterProfile } from '@pipelines/app/models/user-filter-profile';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { QueryParamsService } from '@pipelines/app/services/query-params.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@pipelines/app/components/grid/confirmation-modal/confirmation-modal.component';
import { PipelineViewManager } from '@pipelines/app/manager/pipeline-view.manager';
import { PipelineView } from '@pipelines/app/models/pipeline-view';

@Component({
	selector: 'pipelines-left-nav',
	templateUrl: './left-nav.component.html',
	styleUrls: ['./left-nav.component.scss'],
})
export class LeftNavComponent implements OnInit {
	private readonly pipelineViewManager = inject(PipelineViewManager);
	private readonly queryParamsService = inject(QueryParamsService);
	private readonly confirmationDialog = inject(MatDialog);

	public userFilterProfile$: Observable<UserFilterProfile>;

	ngOnInit(): void {
		this.userFilterProfile$ = this.pipelineViewManager.userFilterProfile$;
	}

	onApplyView(viewId: string): void {
		this.userFilterProfile$
			.pipe(
				first(),
				map((profile) => [...profile.defaultViews, ...profile.savedViews].find((view) => view.id === viewId)),
			)
			.subscribe((viewToApply) => {
				const queryParams = this.buildQueryParams(viewToApply);
				this.queryParamsService.addQueryParams(queryParams, '');
			});
	}

	onDeleteView(viewId: string, viewName: string, event: MouseEvent): void {
		event.stopPropagation();

		this.confirmationDialog.open(ConfirmationModalComponent, {
			data: { viewId, viewName },
			panelClass: 'rounded-xl-dialog',
		});
	}

	private buildQueryParams(viewToApply: PipelineView) {
		const queryParams = {
			filterModel: JSON.stringify(viewToApply.filterModel),
		};

		const stringifiedSortModel = JSON.stringify(viewToApply.sortModel);
		if (stringifiedSortModel) {
			queryParams['sortModel'] = stringifiedSortModel;
		}

		const searchText = viewToApply.searchText;
		if (searchText) {
			queryParams['searchText'] = searchText;
		}

		return queryParams;
	}
}
