<div class="w-64 leftnav fixed top-0 bottom-0 left-0">
	<div class="flex justify-center pt-4 pb-4">
		<img class="h-8" alt="nbkc logo" src="/assets/img/logo-w.svg" />
	</div>

	<app-divider [classes]="'text-sm'" [text]="'Default Views'" [color]="'white'"></app-divider>

	@if (userFilterProfile$ | async; as profile) {
		<div class="px-4">
			@for (defaultView of profile.defaultViews; track defaultView.id) {
				<div class="flex">
					<button
						class="flex-grow py-1.5 px-2 text-sm text-white hover:bg-white hover:bg-opacity-20 rounded text-left"
						matTooltip="{{ defaultView.description }}"
						[matTooltipClass]="'transparent-tooltip'"
						(click)="onApplyView(defaultView.id)"
						matTooltipPosition="right"
					>
						<span class="flex items-center">
							<i class="w-5 fa-regular fa-{{ defaultView.iconName }}"></i>
							<span class="ml-2">{{ defaultView.displayName }}</span>
						</span>
					</button>
				</div>
			}
		</div>
	} @else {
		<div class="flex justify-center p4">
			<mat-spinner color="primary" diameter="30"></mat-spinner>
		</div>
	}

	<app-divider [classes]="'text-sm'" [text]="'My Views'" [color]="'white'"></app-divider>

	@if (userFilterProfile$ | async; as profile) {
		<div class="px-4">
			@for (savedView of profile.savedViews; track savedView.id) {
				<div class="flex items-center group">
					<button
						class="flex-grow py-1.5 pr-2 pl-2 text-sm text-white hover:bg-white hover:bg-opacity-20 rounded w-full"
						matTooltip="{{ savedView.description }}"
						[matTooltipClass]="'transparent-tooltip'"
						(click)="onApplyView(savedView.id)"
						matTooltipPosition="right"
					>
						<div class="flex items-center w-full">
							<i class="w-5 flex-shrink-0 fa-regular fa-{{ savedView.iconName }}"></i>
							<span class="ml-2 truncate block max-w-[180px]">{{ savedView.displayName }}</span>
							<i
								class="fa-regular fa-trash text-white opacity-0 group-hover:opacity-50 hover:opacity-100 hover:bg-white hover:bg-opacity-30 ml-auto cursor-pointer transition-all rounded p-1 flex-shrink-0"
								(click)="onDeleteView(savedView.id, savedView.displayName, $event)"
							></i>
						</div>
					</button>
				</div>
			}
		</div>
	} @else {
		<div class="flex justify-center p4">
			<mat-spinner diameter="30"></mat-spinner>
		</div>
	}
</div>
