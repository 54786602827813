import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateFormatter } from '@common/lib/utilities/date-formatter.utility';
import { WithdrawEvent } from '@pipelines/app/models/requests/intents/withdraw-event';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PipelineEventManager } from '@pipelines/app/manager/pipeline-event.manager';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'pipelines-withdraw-application-dialog',
	templateUrl: './withdraw-application-dialog.component.html',
	styleUrls: ['./withdraw-application-dialog.component.scss'],
})
export class WithdrawApplicationDialogComponent implements OnInit {
	private readonly formBuilder = inject(FormBuilder);
	private readonly dialogRef = inject(MatDialogRef<WithdrawApplicationDialogComponent>);
	private readonly pipelineEventManager = inject(PipelineEventManager);
	private readonly data = inject(MAT_DIALOG_DATA);
	private readonly snackBar = inject(MatSnackBar);

	withdrawApplicationForm: FormGroup;
	maxWithdrawReasonLength = 100;
	isLoading = false;
	applicationId: string;
	email: string;
	createdDate: string;
	createdTime: string;

	ngOnInit(): void {
		this.withdrawApplicationForm = this.formBuilder.group({
			commentText: ['', [Validators.required, Validators.maxLength(this.maxWithdrawReasonLength)]],
		});

		this.createdDate = this.getFormattedDate();
		this.createdTime = this.getFormattedTime();
		this.applicationId = this.data.applicationId;
		this.email = this.data.userEmail;
	}

	public onWithdrawApplication(): void {
		if (this.withdrawApplicationForm.valid) {
			this.isLoading = true;

			const withdrawEvent = new WithdrawEvent();
			withdrawEvent.notes = [this.withdrawApplicationForm.value.commentText];

			this.pipelineEventManager.processApplicationEvent(withdrawEvent, this.applicationId).subscribe({
				next: () => {
					this.isLoading = false;
					this.dialogRef.close();
				},
				error: () => {
					this.isLoading = false;
					this.snackBar.open('An error occurred while processing your request. Please try again.', null, {
						duration: 3000,
					});
					this.dialogRef.close();
				},
			});
		}
	}

	onCancel(): void {
		this.dialogRef.close();
	}

	private getFormattedDate(): string {
		return DateFormatter.formatDate(new Date(this.data.createdDate), 'default', {
			month: 'long',
			day: 'numeric',
			year: 'numeric',
		});
	}

	private getFormattedTime(): string {
		return DateFormatter.formatTime(new Date(this.data.createdDate), 'default', {
			hour: '2-digit',
			minute: '2-digit',
		});
	}
}
