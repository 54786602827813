import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { SaveViewRequest } from '@pipelines/app/models/requests/save-view.request';
import { ActivatedRoute } from '@angular/router';
import { PipelineViewManager } from '@pipelines/app/manager/pipeline-view.manager';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Observable } from 'rxjs';
import { UserFilterProfile } from '@pipelines/app/models/user-filter-profile';
import { map } from 'rxjs/operators';

@Component({
	standalone: true,
	selector: 'pipelines-save-view-modal',
	templateUrl: './save-view-modal.component.html',
	styleUrls: ['./save-view-modal.component.scss'],
	imports: [
		ReactiveFormsModule,
		MatFormField,
		MatSelect,
		MatSelectTrigger,
		MatOption,
		MatInput,
		MatButton,
		MatLabel,
		MatHint,
		MatProgressSpinner,
		NgClass,
	],
})
export class SaveViewModalComponent implements OnInit {
	private readonly pipelineViewManager = inject(PipelineViewManager);
	private readonly dialogRef = inject(MatDialogRef<SaveViewModalComponent>);
	private readonly formBuilder = inject(FormBuilder);
	private readonly route = inject(ActivatedRoute);

	saveViewForm: FormGroup;
	orderValues: number[] = [];
	maxViewNameLength = 25;
	maxDescriptionLength = 100;
	isLoading = false;

	public readonly icons = [
		'bicycle',
		'croissant',
		'chart-simple',
		'chart-line',
		'thumbs-up',
		'flux-capacitor',
		'star',
		'ranking-star',
		'starship',
		'starship-freighter',
		'comet',
		'poo-storm',
		'duck',
		'user-astronaut',
		'sword',
		'egg-fried',
	];

	public userFilterProfile$: Observable<UserFilterProfile>;

	ngOnInit(): void {
		this.saveViewForm = this.formBuilder.group({
			icon: [this.icons[0], Validators.required],
			viewOrder: [null, Validators.required],
			viewName: ['', [Validators.required, Validators.maxLength(this.maxViewNameLength)]],
			viewDescription: ['', [Validators.required, Validators.maxLength(this.maxDescriptionLength)]],
		});

		this.userFilterProfile$ = this.pipelineViewManager.userFilterProfile$;

		this.userFilterProfile$.pipe(map((profile) => profile?.savedViews?.length ?? 0)).subscribe((count) => {
			this.orderValues = Array.from({ length: count + 1 }, (_, i) => i + 1);
		});
	}

	onCancel(): void {
		this.dialogRef.close();
	}

	async onSave(): Promise<void> {
		if (this.saveViewForm.valid) {
			this.isLoading = true;
			const saveViewRequest = this.buildSaveViewRequest();
			this.pipelineViewManager.saveView(saveViewRequest).subscribe({
				next: () => {
					this.isLoading = false;
					this.dialogRef.close();
				},
			});
		}
	}

	private buildSaveViewRequest(): SaveViewRequest {
		const saveViewRequest = new SaveViewRequest();

		const formValues = this.saveViewForm.value;
		const queryParams = this.route.snapshot.queryParams;

		saveViewRequest.iconName = formValues.icon;
		saveViewRequest.order = formValues.viewOrder;
		saveViewRequest.displayName = formValues.viewName;
		saveViewRequest.description = formValues.viewDescription;
		saveViewRequest.isDefault = false;
		saveViewRequest.searchText = queryParams['searchText'] ?? '';
		saveViewRequest.filterModel = this.parseQueryParams('filterModel') ?? {};
		saveViewRequest.sortModel = this.parseQueryParams('sortModel') ?? [{ colId: 'createdDate', sort: 'desc' }];

		return saveViewRequest;
	}

	private parseQueryParams(paramName: string): any {
		const value = this.route.snapshot.queryParams[paramName];
		if (value) {
			return JSON.parse(value);
		}

		return null;
	}
}
