import { computed, Injectable, signal } from '@angular/core';
import { Progress } from '@experience/app/models/onboarding/progress.model';
import { LookupGroup } from '@experience/app/models/tenant/lookup-group.model';
import { LookupItem } from '@experience/app/models/tenant/lookup-item.model';
import { Product } from '@experience/app/models/tenant/product.model';
import { ApplicationKind } from '../models/enums/application-kind';

@Injectable({
	providedIn: 'root',
})
export class InternalStateService {
	private readonly state = {
		$isUpconverted: signal<boolean>(true),
		$applicationKind: signal<string>(''),
		$progress: signal<Progress>({} as Progress),
		$lookups: signal<Record<string, LookupItem[]>>({} as Record<string, LookupItem[]>),
		$products: signal<Product[]>([]),
		$currentScreen: signal<string>(undefined),
		$reviewModeEnabled: signal<boolean>(false),
	};

	readonly $isOldExperience = this.state.$isUpconverted.asReadonly(); // isUpConverted
	readonly $applicationKind = this.state.$applicationKind.asReadonly();
	readonly $progress = this.state.$progress.asReadonly();
	readonly $lookups = this.state.$lookups.asReadonly();
	readonly $products = this.state.$products.asReadonly();
	readonly $isPersonal = computed(() => this.state.$applicationKind() === 'Personal');
	readonly $currentScreen = this.state.$currentScreen.asReadonly();
	readonly $reviewModeEnabled = this.state.$reviewModeEnabled.asReadonly();

	constructor() {}

	setIsOldExperience(isOldExperience: boolean) {
		this.state.$isUpconverted.set(isOldExperience);
	}

	setApplicationKind(kind: ApplicationKind) {
		if (kind !== undefined) {
			this.state.$applicationKind.set(kind);
		}
	}

	setProgress(progress: Progress) {
		this.state.$progress.set({
			...this.state.$progress(),
			...progress,
		});
	}

	setLookups(lookupGroups: LookupGroup[]): void {
		const lookups: Record<string, LookupItem[]> = {};
		lookupGroups.forEach((lookup: LookupGroup) => {
			//Remove "Personal Experience" or "Business Experience" to simplify
			if (lookup.name.includes('Experience')) {
				const name = lookup.name.slice(20).replaceAll(' ', '');
				lookups[name] = lookup.items;
			} else {
				//Business Structures and Business Industries
				const name = lookup.name.replaceAll(' ', '');
				lookups[name] = lookup.items;
			}
		});
		this.state.$lookups.set(lookups);
	}

	setProducts(products: Product[]): void {
		this.state.$products.set(products);
	}

	setCurrentScreen(currentScreen: string): void {
		this.state.$currentScreen.set(currentScreen);
	}

	setReviewMode(enabled: boolean): void {
		this.state.$reviewModeEnabled.set(enabled);
	}

	clearState(): void {
		this.setIsOldExperience(undefined);
		this.setLookups([]);
		this.setApplicationKind(undefined);
		this.setReviewMode(false);
		this.setProgress({
			lastVisitedScreen: undefined,
			visitedScreens: [],
		});
	}
}
