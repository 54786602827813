import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DateFormatter } from '@common/lib/utilities/date-formatter.utility';

@Component({
	selector: 'pipelines-documents-uploaded-renderer',
	templateUrl: './documents-uploaded-renderer.component.html',
	styleUrls: ['./documents-uploaded-renderer.component.scss'],
})
export class DocumentsUploadedRendererComponent implements ICellRendererAngularComp {
	public numDocuments: number;
	public maxDocumentIconsToRender = 5;
	public lastDocumentUploadDate: string;

	public agInit(params: ICellRendererParams): void {
		this.numDocuments = +params.data.documentsUploaded;
		this.setLastDocumentUploadDate(params.value);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public refresh(params: ICellRendererParams): boolean {
		return false;
	}

	private setLastDocumentUploadDate(dateStr: string): void {
		if (this.numDocuments > 0 && dateStr) {
			const date = new Date(dateStr);
			this.lastDocumentUploadDate = `Last upload: ${this.getDate(date)} at ${this.getTime(date).toLowerCase()}`;
		}
	}

	private getDate(date: Date): string {
		return DateFormatter.formatDate(date, 'default', { month: 'numeric', day: 'numeric', year: '2-digit' });
	}

	private getTime(date: Date): string {
		return DateFormatter.formatTime(date, 'default', { hour: 'numeric', minute: '2-digit' });
	}
}
