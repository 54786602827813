import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
	selector: 'pipelines-array-content-renderer',
	templateUrl: './array-content-renderer.component.html',
	standalone: true,
	imports: [MatTooltip],
	styleUrls: ['./array-content-renderer.component.scss'],
})
export class ArrayContentRendererComponent implements ICellRendererAngularComp {
	private readonly maxItems = 5;

	public readonly aiApprovedText = 'Bluefin AI reviewed and approved the uploaded business documents.';
	public displayItems: string[] = [];
	public remainingText: string = '';
	public tooltipContent: string = '';
	public iconName: string;

	public agInit(params: ICellRendererParams): void {
		const columnName = params.column.getColId();
		if (columnName === 'reviewReasons') {
			this.iconName = 'fa-note';
		} else {
			this.iconName = 'fa-tag';
		}

		const items = params.value;
		this.displayItems = items.slice(0, this.maxItems);
		const remaining = items.length - this.maxItems;
		if (remaining > 0) {
			this.remainingText = `+${remaining}`;
		}

		this.tooltipContent = items.map((item, index) => `${index + 1}. ${item}`).join('\n');
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public refresh(params: ICellRendererParams): boolean {
		return false;
	}
}
