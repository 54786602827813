import { inject, Injectable } from '@angular/core';
import { PipelineApiService } from '@pipelines/app/services/pipeline-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserFilterProfile } from '@pipelines/app/models/user-filter-profile';
import { map, tap } from 'rxjs/operators';
import { SaveViewRequest } from '@pipelines/app/models/requests/save-view.request';

@Injectable({
	providedIn: 'root',
})
export class PipelineViewManager {
	private readonly pipelineApiService = inject(PipelineApiService);

	private userFilterProfile = new BehaviorSubject<UserFilterProfile>(null);
	public userFilterProfile$ = this.userFilterProfile.asObservable();

	constructor() {
		this.getViews().subscribe((response) => {
			this.userFilterProfile.next(response);
		});
	}

	deleteView(viewId: string): Observable<UserFilterProfile> {
		return this.pipelineApiService.deleteView$(viewId).pipe(
			map((response) => this.sortViews(response)),
			tap((response) => this.userFilterProfile.next(response)),
		);
	}

	saveView(saveViewRequest: SaveViewRequest): Observable<UserFilterProfile> {
		return this.pipelineApiService.saveView$(saveViewRequest).pipe(
			map((response) => this.sortViews(response)),
			tap((response) => this.userFilterProfile.next(response)),
		);
	}

	private getViews(): Observable<UserFilterProfile> {
		return this.pipelineApiService.getViews$().pipe(map((response) => this.sortViews(response)));
	}

	private sortViews(profile: UserFilterProfile): UserFilterProfile {
		return {
			...profile,
			defaultViews: [...profile.defaultViews].sort((a, b) => a.order - b.order),
			savedViews: [...profile.savedViews].sort((a, b) => a.order - b.order),
		};
	}
}
