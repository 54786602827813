<div class="p-6 max-w-md rounded-xl relative">
	@if (isLoading) {
		<div class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-10">
			<mat-spinner diameter="40"></mat-spinner>
		</div>
	}

	<h2 class="text-xl font-bold mb-6">Create New View</h2>

	<form [formGroup]="saveViewForm" (ngSubmit)="onSave()">
		<div class="space-y-4">
			<!-- Icon and View Order Row -->
			<div class="flex gap-4">
				<mat-form-field appearance="outline" class="w-7/12">
					<mat-label>Icon</mat-label>
					<mat-select required formControlName="icon" placeholder="Icon">
						<mat-select-trigger>
							<span class="flex items-center">
								<i class="w-5 fa-regular fa-{{ saveViewForm.get('icon')?.value }}"></i>
								<span class="ml-2">{{ saveViewForm.get('icon')?.value }}</span>
							</span>
						</mat-select-trigger>
						@for (icon of icons; track icon) {
							<mat-option [value]="icon">
								<span class="flex items-center">
									<i class="w-5 fa-regular fa-{{ icon }}"></i>
									<span class="ml-2">{{ icon }}</span>
								</span>
							</mat-option>
						}
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="w-5/12">
					<mat-label>View Order</mat-label>
					<mat-select formControlName="viewOrder" placeholder="View Order">
						@for (order of orderValues; track order) {
							<mat-option [value]="order">
								{{ order }}
							</mat-option>
						}
					</mat-select>
				</mat-form-field>
			</div>

			<!-- View Name -->
			<mat-form-field appearance="outline" class="w-full">
				<mat-label>View Name</mat-label>
				<input matInput formControlName="viewName" [maxlength]="maxViewNameLength" />
				<mat-hint align="end">
					{{ maxViewNameLength - (saveViewForm.get('viewName')?.value?.length || 0) }} character(s) remaining
				</mat-hint>
			</mat-form-field>

			<!-- View Description -->
			<mat-form-field appearance="outline" class="w-full">
				<mat-label>View Description</mat-label>
				<textarea
					matInput
					formControlName="viewDescription"
					rows="4"
					[maxLength]="maxDescriptionLength"
				></textarea>
				<mat-hint align="end">
					{{ maxDescriptionLength - (saveViewForm.get('viewDescription')?.value?.length || 0) }} character(s)
					remaining
				</mat-hint>
			</mat-form-field>
		</div>

		<!-- Cancel / Save Buttons -->
		<div class="flex justify-between mt-6">
			<button mat-button type="button" (click)="onCancel()" class="text-blue-500 rounded-full px-6">
				Cancel
			</button>

			<button
				mat-flat-button
				type="submit"
				[disabled]="!saveViewForm.valid"
				class="rounded-full px-6"
				[ngClass]="{
					'bg-blue-500 text-white hover:bg-blue-600': saveViewForm.valid,
					'bg-gray-300 text-gray-500 cursor-not-allowed': !saveViewForm.valid,
				}"
			>
				Save
			</button>
		</div>
	</form>
</div>
