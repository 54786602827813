import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { PipelineViewManager } from '@pipelines/app/manager/pipeline-view.manager';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
	standalone: true,
	selector: 'pipelines-confirmation-modal',
	templateUrl: './confirmation-modal.component.html',
	styleUrls: ['./confirmation-modal.component.scss'],
	imports: [MatButton, MatProgressSpinner],
})
export class ConfirmationModalComponent {
	private readonly pipelineViewManager = inject(PipelineViewManager);
	private readonly dialogRef = inject(MatDialogRef<ConfirmationModalComponent>);
	public readonly data = inject(MAT_DIALOG_DATA) as { viewId: string; viewName: string };

	public isLoading = false;

	onCancel(): void {
		this.dialogRef.close();
	}

	async onDelete(): Promise<void> {
		this.isLoading = true;
		this.pipelineViewManager.deleteView(this.data.viewId).subscribe({
			next: () => {
				this.isLoading = false;
				this.dialogRef.close();
			},
		});
	}
}
