import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GridReadyEvent } from '@pipelines/app/models/grid-ready.interface';
import { DocumentsUploadedRendererComponent } from '@pipelines/app/components/grid/renderers/documents-uploaded-renderer/documents-uploaded-renderer.component';
import { ActionsRendererComponent } from '@pipelines/app/components/grid/renderers/actions-renderer/actions-renderer.component';
import { ActivatedRoute, Params } from '@angular/router';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { GridDatasource } from '@pipelines/app/components/grid/datasource/grid-datasource';
import { ApplicantRendererComponent } from '@pipelines/app/components/grid/renderers/applicant-renderer/applicant-renderer.component';
import { DateRendererComponent } from '@pipelines/app/components/grid/renderers/date-renderer/date-renderer.component';
import { ColumnMenuTabs } from '@pipelines/app/components/grid/column-menu-tabs.enum';
import { filter, first, map, startWith, takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { ApplicationTypeRendererComponent } from '@pipelines/app/components/grid/renderers/application-type-renderer/application-type-renderer.component';
import { StatusRendererComponent } from '@pipelines/app/components/grid/renderers/status-renderer/status-renderer.component';
import { ColumnName } from '@pipelines/app/components/grid/column-names.consts';
import { filterApplicationTypeMap, filterStatusMap } from '@pipelines/app/components/grid/filter-map';
import { toAgGridFilterInstance, toBlueFinTextFilter } from '@pipelines/app/components/grid/filter.util';
import { MatDialog } from '@angular/material/dialog';
import { QueryParamsService } from '@pipelines/app/services/query-params.service';
import { SaveViewModalComponent } from '@pipelines/app/components/grid/save-view-modal/save-view-modal.component';
import { ArrayContentRendererComponent } from '@pipelines/app/components/grid/renderers/array-content-renderer/array-content-renderer.component';
import { PipelineEventManager } from '@pipelines/app/manager/pipeline-event.manager';

@Component({
	selector: 'pipelines-grid',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, OnDestroy {
	@ViewChild('searchInput') public searchInput: ElementRef<HTMLInputElement>;
	public gridOptions: GridOptions;
	public totalRows: number;
	public pageSizeOptions: number[] = [25, 50, 100, 150, 200];
	public pageSize: number;
	public searchText: string;
	public columnDefs$: Observable<ColDef[]>;
	public isResetViewDisabled: boolean;
	public isSaveViewDisabled: boolean;
	private destroy$: Subject<void> = new Subject<void>();
	private queryParams: Params;
	private isInitialLoad = true;

	private gridApi: GridApi;

	private pipelineEventManager = inject(PipelineEventManager);
	private route = inject(ActivatedRoute);
	private queryParamsService = inject(QueryParamsService);
	private gridDatasource = inject(GridDatasource);
	private readonly saveViewDialog = inject(MatDialog);

	public ngOnInit(): void {
		this.setupQueryParamsSubscriptions();
		this.initColumnDefs();
		this.setGridOptions();
		this.setupApplicationEventSubscription();
	}

	public ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public onGridReady({ api }: GridReadyEvent): void {
		this.gridApi = api;
	}

	public onGridUpdated(): void {
		if (_.isNil(this.gridApi)) {
			this.isResetViewDisabled = true;
		} else {
			this.isResetViewDisabled =
				_.isEmpty(this.gridApi.getFilterModel()) && !this.searchText && !this.queryParams['sortModel'];
		}
		this.totalRows = this.gridApi?.getDisplayedRowCount() ?? 0;
		if (this.totalRows > 0 && this.isInitialLoad) {
			this.setInitialFilterInstances();
			this.setSaveViewDisabled();
			this.isInitialLoad = false;
		}
		this.gridApi?.sizeColumnsToFit();
	}

	public onSearch(): void {
		const searchInputValue = this.searchInput.nativeElement.value.trim();
		this.queryParamsService.addQueryParams({ searchText: searchInputValue.length > 0 ? searchInputValue : null });
		this.gridApi.refreshServerSide({ purge: true });
	}

	public onFilterChanged(): void {
		this.setSaveViewDisabled();
		const filterModel = this.modifyOutgoingFilter();
		this.queryParamsService.addQueryParams({ filterModel: JSON.stringify(filterModel) });
	}

	private setSaveViewDisabled(): void {
		this.isSaveViewDisabled =
			this.gridApi?.getFilterModel() &&
			_.isEmpty(this.gridApi.getFilterModel()) &&
			_.isEmpty(this.queryParams['searchText']);
	}

	public onSortChanged(): void {
		let sortModel = null;
		const sortedColumn = _.find(this.gridApi.getColumnState(), (column) => column.sort !== null);
		if (sortedColumn) {
			const sortObject = _.pick(sortedColumn, ['colId', 'sort']);
			sortModel = JSON.stringify([sortObject]);
		}
		this.queryParamsService.addQueryParams({ sortModel });
	}

	public setupQueryParamsSubscriptions(): void {
		this.route.queryParams.subscribe((queryParams) => {
			this.queryParams = queryParams;
			this.searchText = queryParams['searchText'] ?? '';
			this.pageSize = queryParams['pageSize'] ?? this.pageSizeOptions[0];

			this.setSaveViewDisabled();

			// ag-grid handles auto-refreshing the grid when either the filter or sort model changes
			// since searchText is outside the grid and controlled by us, we need to force a refresh,
			// but only if the grid hasn't already auto-refreshed
			const didUpdateFilterModel = this.updateFilterModelFromParams();
			if (!didUpdateFilterModel && this.searchText) {
				this.gridApi.refreshServerSide({ purge: true });
			}
		});
	}

	public initColumnDefs(): void {
		this.columnDefs$ = this.gridDatasource.columnDefs$.pipe(
			first((columnDefs) => columnDefs.length > 0),
			map((columnDefinitions) =>
				columnDefinitions?.filter((columnDefinition) => columnDefinition.cellRenderer !== 'hiddenRenderer'),
			),
			startWith([] as ColDef[]),
		);
	}

	public onResetView(): void {
		this.gridApi.resetColumnState();
		this.gridApi.setFilterModel(null);
		this.queryParamsService.addQueryParams({ searchText: null, sortModel: null, pageSize: null }, '');
		this.gridApi.refreshServerSide({ purge: true });
	}

	public onSaveView(): void {
		this.saveViewDialog.open(SaveViewModalComponent, {
			panelClass: 'rounded-xl-dialog',
		});
	}

	private setupApplicationEventSubscription(): void {
		this.pipelineEventManager.processingEvent$
			.pipe(
				filter((response) => response !== null),
				takeUntil(this.destroy$),
			)
			.subscribe((response) => {
				if (response.shouldRefreshGrid) {
					this.gridApi.refreshServerSide({ purge: true });
				}
			});
	}

	private modifyOutgoingFilter(): { [key: string]: any } {
		const filterModel = this.gridApi.getFilterModel();

		toBlueFinTextFilter(filterModel, ColumnName.applicationType, filterApplicationTypeMap);
		toBlueFinTextFilter(filterModel, ColumnName.status, filterStatusMap);

		return filterModel;
	}

	private setInitialFilterInstances(): void {
		this.updateFilterModelFromParams();
	}

	private updateFilterModelFromParams(): boolean {
		const filterModelFromParams = JSON.parse(this.queryParams['filterModel'] ?? null);
		if (this.gridApi && filterModelFromParams && Object.keys(filterModelFromParams).length > 0) {
			const agGridFilterModel = {};
			for (const [key, value] of Object.entries(filterModelFromParams)) {
				if ([ColumnName.applicationType, ColumnName.status].includes(key)) {
					agGridFilterModel[key] = toAgGridFilterInstance(key, value);
				} else {
					agGridFilterModel[key] = value;
				}
			}
			this.gridApi.setFilterModel(agGridFilterModel);
			return true;
		}
		return false;
	}

	private setGridOptions(): void {
		this.gridOptions = {
			rowModelType: 'serverSide',
			serverSideDatasource: this.gridDatasource,
			cacheBlockSize: this.pageSize,
			components: {
				actionsRenderer: ActionsRendererComponent,
				applicantRenderer: ApplicantRendererComponent,
				dateRenderer: DateRendererComponent,
				documentsUploadedRenderer: DocumentsUploadedRendererComponent,
				statusRenderer: StatusRendererComponent,
				applicationTypeRenderer: ApplicationTypeRendererComponent,
				arrayContentRenderer: ArrayContentRendererComponent,
			},
			defaultColDef: {
				flex: 1,
				minWidth: 100,
				sortable: false,
				lockPosition: true,
				resizable: true,
				menuTabs: [ColumnMenuTabs.filter],
				cellStyle: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				},
			},
			pagination: true,
			paginationPageSize: this.pageSize,
			paginationPageSizeSelector: this.pageSizeOptions,
			onSortChanged: this.onSortChanged.bind(this),
			onFilterChanged: this.onFilterChanged.bind(this),
			onModelUpdated: this.onGridUpdated.bind(this),
			onGridReady: this.onGridReady.bind(this),
		};
	}
}
