<div class="p-6 max-w-md rounded-xl relative">
	@if (isLoading) {
		<div class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-10">
			<mat-spinner diameter="40"></mat-spinner>
		</div>
	}

	<!-- Header and Subheader -->
	<h2 class="text-xl font-bold mb-3">Are you sure you want to withdraw this application?</h2>
	<p class="subheader text-base">
		Once you withdraw this application, the user will not be able to access or continue this application.
	</p>

	<!-- Created Date and User -->
	<div class="flex flex-col my-2">
		<div>
			<span class="font-extrabold text-xs mr-1">Created:</span>
			<span class="font-normal text-xs">{{ createdDate }} at {{ createdTime }}</span>
		</div>
		<div>
			<span class="font-extrabold text-xs mr-1">User:</span>
			<span class="font-normal text-xs">{{ email }}</span>
		</div>
	</div>

	<!-- Reason for withdrawal -->
	<form [formGroup]="withdrawApplicationForm" (ngSubmit)="onWithdrawApplication()">
		<mat-form-field appearance="outline" class="w-full mt-3">
			<mat-label>Reason for Withdrawal</mat-label>
			<textarea matInput formControlName="commentText" rows="4" [maxlength]="maxWithdrawReasonLength"></textarea>
			<mat-hint align="end">
				{{ maxWithdrawReasonLength - (withdrawApplicationForm.get('commentText')?.value?.length || 0) }}
				character(s) remaining
			</mat-hint>
		</mat-form-field>

		<!-- Cancel / Withdraw Buttons -->
		<div class="flex justify-between mt-6">
			<button mat-button type="button" (click)="onCancel()" class="text-blue-500 rounded-full px-6">
				Cancel
			</button>

			<button
				mat-flat-button
				type="submit"
				[disabled]="!withdrawApplicationForm.valid"
				class="rounded-full px-6"
				[ngClass]="{
					'bg-blue-500 text-white hover:bg-blue-600': withdrawApplicationForm.valid,
					'bg-gray-300 text-gray-500 cursor-not-allowed': !withdrawApplicationForm.valid,
				}"
			>
				Withdraw
			</button>
		</div>
	</form>
</div>
