import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { ApiSortModel } from '@pipelines/app/models/responses/api-sort-model';
import { SortModel } from '@pipelines/app/models/sort-model.interface';

export class ApiSortModelToSortModelAdapter extends AdapterBase<ApiSortModel, SortModel> {
	adapt(source: ApiSortModel): SortModel {
		const sortModel = new SortModel();

		if (source) {
			sortModel.colId = source.columnId;
			sortModel.sort = source.sort;
		}

		return sortModel;
	}
}
