<div class="p-6 max-w-md rounded-xl">
	@if (isLoading) {
		<div class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80">
			<mat-spinner diameter="40"></mat-spinner>
		</div>
	}

	<div class="space-y-4">
		<p class="text-center text-gray-700">Are you sure you want to delete this view?</p>

		<p class="text-center text-xl font-extrabold">
			{{ data.viewName }}
		</p>

		<p class="text-sm text-center text-gray-500">This action cannot be undone.</p>
	</div>

	<div class="flex justify-between mt-6">
		<button mat-button type="button" (click)="onCancel()" class="text-blue-500 rounded-full px-6">Cancel</button>
		<button
			mat-flat-button
			type="button"
			(click)="onDelete()"
			class="bg-red-500 text-white hover:bg-red-600 rounded-full px-6"
		>
			Delete
		</button>
	</div>
</div>
