<div
	[matTooltip]="tooltipContent"
	matTooltipPosition="after"
	class="cursor-default inline-flex items-center gap-1"
	[matTooltipClass]="'multiline-tooltip transparent-tooltip'"
>
	@for (displayItem of displayItems; track displayItem) {
		@if (displayItem === aiApprovedText) {
			<i class="fa-regular fa-robot text-xl mr-1"></i>
		} @else {
			<i class="fa-regular {{ iconName }} text-xl mr-1"></i>
		}
	}

	@if (remainingText) {
		<span class="font-semibold">{{ remainingText }}</span>
	}
</div>
