import { ProcessApplicationResponse } from '@pipelines/app/models/responses/process-application/process-application.response';
import { ProcessApplicationResult } from '@pipelines/app/models/process-application-result';
import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { WithdrawApplicationResponse } from '@pipelines/app/models/responses/process-application/withdraw-application.response';

export class ProcessApplicationResponseToProcessApplicationResultAdapter extends AdapterBase<
	ProcessApplicationResponse,
	ProcessApplicationResult
> {
	adapt(source: ProcessApplicationResponse): ProcessApplicationResult {
		const processApplicationResult = new ProcessApplicationResult();

		if (source) {
			processApplicationResult.result = source.result;
			processApplicationResult.shouldRefreshGrid = (source.result as WithdrawApplicationResponse) !== null;
		}

		return processApplicationResult;
	}
}
