import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class QueryParamsService {
	private router = inject(Router);
	private route = inject(ActivatedRoute);

	addQueryParams(queryParams: any, queryParamsHandling: QueryParamsHandling = 'merge'): void {
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams,
			queryParamsHandling,
		});
	}
}
