import { ColumnName } from '@pipelines/app/components/grid/column-names.consts';
import { toFilterInstanceByValue } from '@pipelines/app/components/grid/filter-map';

export const toAgGridFilterInstance = (columnKey: string, filterValue: { [p: string]: any }): { [p: string]: any } => {
	if (columnKey === ColumnName.applicationType || columnKey === ColumnName.status) {
		const filterValues = filterValue['values'];
		return {
			values: toFilterInstanceByValue(columnKey, filterValues),
			filterType: 'set',
		};
	}

	return null;
};

export const toBlueFinSetFilter = (
	filterModel: { [p: string]: any },
	columnName: string,
	filterMap: { [p: string]: any },
): void => {
	if (filterModel[columnName]) {
		const values = filterModel[columnName].values;
		for (const [key, value] of Object.entries(filterMap)) {
			const existingIndex = values.indexOf(key);
			if (existingIndex !== -1) {
				filterModel[columnName] = value;
			}
		}
	}
};

export const toBlueFinTextFilter = (
	filterModel: { [p: string]: any },
	columnName: string,
	filterMap: { [p: string]: any },
): void => {
	if (filterModel[columnName]) {
		const values = filterModel[columnName].values;
		for (const [key, value] of Object.entries(filterMap)) {
			const existingIndex = values.indexOf(key);
			filterModel[columnName].values[existingIndex] = value;
		}
	}
};
