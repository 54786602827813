import { ApiPipelineView } from '@pipelines/app/models/responses/api-pipeline-view';
import { PipelineView } from '@pipelines/app/models/pipeline-view';
import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { ApiSortModelToSortModelAdapter } from '@pipelines/app/adapters/api-sort-model-to-sort-model.adapter';

const apiSortModelToSortModelAdapter = new ApiSortModelToSortModelAdapter();

export class ApiPipelineViewToPipelineViewAdapter extends AdapterBase<ApiPipelineView, PipelineView> {
	adapt(source: ApiPipelineView): PipelineView {
		const pipelineView = new PipelineView();

		if (source) {
			pipelineView.id = source.id;
			pipelineView.iconName = source.iconName;
			pipelineView.order = source.order;
			pipelineView.displayName = source.displayName;
			pipelineView.description = source.description;
			pipelineView.isDefault = source.isDefault;
			pipelineView.searchText = source.searchText;
			pipelineView.filterModel = source.filterModel;
			pipelineView.sortModel = apiSortModelToSortModelAdapter.adaptCollection(source.sortModel);
		}

		return pipelineView;
	}
}
