import { applicationType } from '@common/lib/constants/application-types';
import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';
import { RowSupplement } from '@pipelines/app/models/row-supplement';

export class RowDef {
	public actions: string;
	public applicationId: string;
	public originationUrl: string;
	public applicationType: applicationType;
	public primaryApplicant: string;
	public primaryUserEmail: string;
	public createdDate: string;
	public status: knownApplicationStatus;
	public documentsUploaded: string;
	public lastDocumentUploadDate: string;
	public submitDate: string;
	public doingBusinessAsName: string;
	public tags: string[];
	public reviewReasons: string[];
	public rowSupplement: RowSupplement;
}
