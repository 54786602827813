import { inject, Injectable } from '@angular/core';
import { PipelineApiService } from '../services/pipeline-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProcessApplicationRequest } from '@pipelines/app/models/requests/process-application-request';
import { tap } from 'rxjs/operators';
import { PipelineEvent } from '@pipelines/app/models/requests/intents/pipeline-event';
import { ProcessApplicationResult } from '@pipelines/app/models/process-application-result';

@Injectable({
	providedIn: 'root',
})
export class PipelineEventManager {
	private readonly pipelineApiService = inject(PipelineApiService);

	private processingEvent = new BehaviorSubject<ProcessApplicationResult>(null);
	public processingEvent$ = this.processingEvent.asObservable();

	processApplicationEvent(
		applicationEvent: PipelineEvent,
		applicationId: string,
	): Observable<ProcessApplicationResult> {
		const processApplicationRequest = new ProcessApplicationRequest();
		processApplicationRequest.applicationId = applicationId;
		processApplicationRequest.event = applicationEvent;

		return this.pipelineApiService
			.processApplication$(processApplicationRequest)
			.pipe(tap((response) => this.processingEvent.next(response)));
	}
}
