import { SortModel } from '@pipelines/app/models/sort-model.interface';

export class SaveViewRequest {
	iconName: string;
	order: number;
	displayName: string;
	description: string;
	isDefault: boolean;
	searchText: string;
	filterModel: Record<string, any>;
	sortModel: SortModel[];
}
