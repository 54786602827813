<div class="h-full w-full flex flex-auto flex-col px-10 py-6">
	<div class="grid-actions flex flex-row items-center pb-6 text-sm">
		<div class="flex-auto">
			<div class="flex">
				<input
					#searchInput
					(keydown.enter)="onSearch()"
					class="grow search-input outline-none border border-r-0 border-solid box-content py-1 pl-3 rounded-l-md border-gray-500 h-10 placeholder-gray-500 text-sm w-full"
					type="text"
					[value]="searchText"
					placeholder="Search"
				/>
				<button
					class="border border-solid box-content rounded-r-md rounded-l-none border-gray-500 py-1 pl-3 h-10"
					mat-button
					(click)="onSearch()"
				>
					<i class="far fa-search text-base mt-1"></i>
				</button>
				<div class="flex items-center">
					<button
						[disabled]="isResetViewDisabled"
						mat-button
						color="primary"
						(click)="onResetView()"
						[ngClass]="{ 'cursor-not-allowed': isResetViewDisabled }"
						class="text-center flex-none ml-3 rounded-lg h-8 p-1 font-normal"
					>
						Reset View
					</button>
					<button
						[disabled]="isSaveViewDisabled"
						mat-button
						color="primary"
						(click)="onSaveView()"
						[ngClass]="{ 'cursor-not-allowed': isSaveViewDisabled }"
						class="text-center flex-none ml-3 rounded-lg h-8 p-1 font-normal"
					>
						Save View
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="h-28">
		<ag-grid-angular
			class="ag-theme-material w-full"
			domLayout="autoHeight"
			[columnDefs]="columnDefs$ | async"
			[gridOptions]="gridOptions"
		>
		</ag-grid-angular>
	</div>
</div>
