import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetRowsRequest } from '@pipelines/app/models/requests/get-rows-request';
import { GridData } from '@pipelines/app/models/grid-data';
import { PipelinesConfiguration } from '@pipelines/app/pipelines.configuration';
import { GridDataResponse } from '@pipelines/app/models/responses/grid-data.response';
import { map } from 'rxjs/operators';
import { GridDataResponseToGridDataAdapter } from '@pipelines/app/adapters/grid-data-response-to-grid-data.adapter';
import { UserFilterProfileResponse } from '@pipelines/app/models/responses/user-filter-profile.response';
import { UserFilterProfileResponseToUserFilterProfileAdapter } from '@pipelines/app/adapters/user-filter-profile-response-to-user-filter-profile.adapter';
import { UserFilterProfile } from '@pipelines/app/models/user-filter-profile';
import { SaveViewRequest } from '@pipelines/app/models/requests/save-view.request';
import { ProcessApplicationRequest } from '@pipelines/app/models/requests/process-application-request';
import { ProcessApplicationResponse } from '@pipelines/app/models/responses/process-application/process-application.response';
import { ProcessApplicationResult } from '@pipelines/app/models/process-application-result';
import { ProcessApplicationResponseToProcessApplicationResultAdapter } from '@pipelines/app/adapters/process-application-response-to-process-application-result.adapter';

const getGridDataResponseToGridDataAdapter = new GridDataResponseToGridDataAdapter();
const userFilterProfileResponseToUserFilterProfileAdapter = new UserFilterProfileResponseToUserFilterProfileAdapter();
const processApplicationResponseToProcessApplicationResultAdapter =
	new ProcessApplicationResponseToProcessApplicationResultAdapter();

@Injectable({
	providedIn: 'root',
})
export class PipelineApiService {
	private readonly http = inject(HttpClient);
	private readonly config = inject(PipelinesConfiguration);

	private readonly baseUrl = `${this.config.experienceApi}/pipeline`;

	public getGridData$(getRowsRequest: GetRowsRequest): Observable<GridData> {
		return this.http
			.post<GridDataResponse>(`${this.baseUrl}`, getRowsRequest)
			.pipe(map((gridDataResponse) => getGridDataResponseToGridDataAdapter.adapt(gridDataResponse)));
	}

	public getViews$(): Observable<UserFilterProfile> {
		return this.http
			.get<UserFilterProfileResponse>(`${this.baseUrl}/views/get`)
			.pipe(map((response) => userFilterProfileResponseToUserFilterProfileAdapter.adapt(response)));
	}

	public saveView$(saveViewRequest: SaveViewRequest): Observable<UserFilterProfile> {
		return this.http
			.post<UserFilterProfileResponse>(`${this.baseUrl}/view/save`, saveViewRequest)
			.pipe(map((response) => userFilterProfileResponseToUserFilterProfileAdapter.adapt(response)));
	}

	public deleteView$(viewId: string): Observable<UserFilterProfile> {
		return this.http
			.delete<UserFilterProfileResponse>(`${this.baseUrl}/view/delete/${viewId}`)
			.pipe(map((response) => userFilterProfileResponseToUserFilterProfileAdapter.adapt(response)));
	}

	public processApplication$(
		processApplicationRequest: ProcessApplicationRequest,
	): Observable<ProcessApplicationResult> {
		return this.http
			.post<ProcessApplicationResponse>(`${this.baseUrl}/process`, processApplicationRequest)
			.pipe(map((response) => processApplicationResponseToProcessApplicationResultAdapter.adapt(response)));
	}
}
